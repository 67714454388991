import React, { useState } from 'react'
import { Modal, Button, Row, Col, Form, Spinner  } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setToggleFalse } from '../../redux/Slice/changeStates'
import { useMediaQuery } from 'react-responsive';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FaRegCalendar } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import useFormvalidation from '../../validations/FormValidation'
import { ContactFormRules } from "../../validations/FormValidationRules";
import Apis from '../../services/Apis'
import { ToastContainer, toast } from 'react-toastify';
import { saveContact } from '../../redux/Slice/master';

const Contact = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state);
    const contact = useSelector((state) => state.master.contactData) 
    const loader = useSelector((state) => state.master.loader) 
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const {saveContactFormdata} = Apis()
    const [date, setDate] = useState(null);
    const [formValues, setFormValues] = useState(
        {
            "name": "",
            "phone": "",
            "email": "",
            "message": "",
            "property": "",
        }
    );

    

    // console.log("toggle", state)
    const handleClose = () => {
        dispatch(setToggleFalse())
    }

    const handleNext = async () => {
        var form_data = new FormData();
        for (var key in values) {
            form_data.append(key, values[key]);
        }
        dispatch(saveContact({ input_data: form_data, setValues, setDate }));
        
            
        
    }

    const { values, setValues, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        ContactFormRules,
        formValues
    );

    const handleDate = (data) => {
        const formattedDate = moment(data).format('MM/DD/YYYY');
        setValues(values => ({ ...values, message: "Schedule Date is : "+ formattedDate, property: "IRA - The Square"}));

        setDate(formattedDate);
    }


    return (
        <Modal show={state.toggle} onHide={handleClose} centered className='contact-form'>
            <Modal.Header closeButton>
                <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className={` ${isMobile ? '' : 'mb-3'}`}>
                    <Col lg={6} md={6} xs={12} className={` ${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control size="md" name="name" type="text" placeholder="Name" onChange={handleChange} value={values.name} />
                        <span className='error mt-3'>{errors.hasOwnProperty('name') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.name} </>}
                        </span>
                    </Col>
                    <Col lg={6} md={6} xs={12} className={` ${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control size="md" name="email" type="text" placeholder="E-Mail" onChange={handleChange} value={values.email} />
                        <span className='error mt-3'>{errors.hasOwnProperty('email') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.email} </>}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} xs={12} className={` ${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control size="md" name="phone" type="text" placeholder="Phone Number" onChange={handleChange} value={values.phone} />
                        <span className='error mt-3'>{errors.hasOwnProperty('phone') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.phone} </>}
                        </span>
                    </Col>
                    <Col lg={6} md={6} xs={12} className={` ${isMobile ? 'mb-3' : ''}`}>
                        <div className='datepicker-container'>
                            <DatePicker selected={date ? new Date(date) : null} onChange={handleDate} className='custom-datepicker' placeholderText="Select Date" />
                            <FaRegCalendar className="calendar-icon" />
                        </div>
                        <span className='error mt-3'>{errors.hasOwnProperty('message') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.message} </>}
                        </span>
                    </Col>
                </Row>
                <Row className={`${isMobile ? 'mt-4' : 'mt-5'}`}>
                    <Col>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                            <label class="form-check-label" for="flexCheckChecked" className='authorize-label'>
                                I authorize representatives of IRA Square to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DNC/NDNC.
                            </label>
                        </div>
                    </Col>
                </Row>
                <button type="submit" className='btn btn-enquiry mt-3' onClick={handleSubmit}>Book a Visit {loader && <Spinner className='loader' animation="border"  />}</button>
                
            </Modal.Body>
        </Modal>
    )
}

export default Contact
