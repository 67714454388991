import { configureStore } from '@reduxjs/toolkit'
import toggleReducer from './Slice/changeStates'
import masterReducer from './Slice/master'

const store = configureStore({
    reducer: {
        toggle: toggleReducer,
        master : masterReducer,
    },
  })
  
  export default store;