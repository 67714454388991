import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    hastoggle: false,
    loader: false,
}

export const toggleSlice = createSlice({
    name: 'localState',
    initialState,
    reducers: {
        setToggleTrue: (state) => {
            state.hastoggle = true; // Update hastoggle to true
        },
        setToggleFalse: (state) => {
            state.hastoggle = false; // Update hastoggle to false
        },
        setLoaderTrue: (state) => {
            state.loader = true; // Update loader to true
        },
        setLoaderFalse: (state) => {
            state.loader = false; // Update loader to false
        },
    }
})

// Action creators are generated for each case reducer function
export const {setToggleTrue, setToggleFalse, setLoaderTrue, setLoaderFalse} = toggleSlice.actions

export default toggleSlice.reducer