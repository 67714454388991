import React from 'react'
import { FaWhatsapp } from "react-icons/fa";

const floatingComponent = () => {
  return (
    <>
        
        <a href="https://wa.api-whatsapp.in/Nsyl70" className="float" target="_blank" rel="noreferrer">
            <FaWhatsapp className='my-float' />
        </a>
    </>
  )
}

export default floatingComponent
