import React from 'react'
import img1 from '../../images/amenity1.webp'
import img2 from '../../images/amenity2.webp'
import img3 from '../../images/amenity3.webp'
import img4 from '../../images/amenity4.webp'
import {Row, Col} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';

const AmenitiesBanner = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  return (
    <>
      <div className='square-image' id="Amenities">
            <div className='container'>
                <div className='title-section'>
                    <h1 >World-Class Amenities for our Villa Owners</h1>
                    <p>The Square offers a 25,000 Sft clubhouse, swimming pool, gym, and other premium amenities. Unwind and enjoy a luxurious lifestyle at our 4 BHK villas in Adibatla.</p>
                    
                </div>
                <Row className='mb-4'>
                  <Col lg={8} className={`${isMobile ? 'mb-3' : ''}`}>
                    <img src={img1} alt="" className='img-fluid first-img'></img>
                  </Col>
                  <Col lg={4}>
                    <Row className='mb-3'>
                      <Col className={`${isMobile ? 'mb-3' : ''}`}>
                        <img src={img2} alt="" className={`img-fluid ${isMobile ? 'w-100' : ''}`} ></img>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <img src={img3} alt="" className={`img-fluid ${isMobile ? 'w-100' : ''}`}></img>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img src={img4} alt="" className='img-fluid'></img>
                  </Col>
                </Row>
            </div>
            
        </div>
        
    </>
  )
}

export default AmenitiesBanner
