import React from 'react'
import * as common from '../component/common'

const Home = () => {
  return (
    <>
      <common.Slider />
      <common.About />
      {/* <common.Amenities /> */}
      <common.ProjectHighlight />
      <common.Smart />
      <common.Facing />
      <common.AmenitiesBanner />
      <common.Location />
      <common.Join />
    </>
  )
}

export default Home
