import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Apis from '../../services/Apis';
import { toast } from 'react-toastify';

const { saveContactFormdata } = Apis()
const notify = (msz) => toast(msz);
// Async thunk to handle the async operation
export const saveContact = createAsyncThunk(
    'saveContact',
    async ({ input_data, setValues, setDate }) => {
        try {
            const response = await saveContactFormdata(input_data);
            if (response.status === 200) {
                notify(response.data);
                setValues(
                    {
                        "name": "",
                        "phone": "",
                        "email": "",
                        "message":"",
                    }
                )
                setDate(null)

                return response.data;
            } else {
                throw new Error('Failed to save contact form data');
            }
        } catch (error) {
            throw error;
        }
    }
);

const masterSlice = createSlice({
    name: "master",
    initialState: {
        contactData: false,
        loader: false,
    },
    extraReducers: (builder) => {
        //Save contact
        builder.addCase(saveContact.pending, (state, action) => {
            state.contactData = false;
            state.loader = true;
        });
        builder.addCase(saveContact.fulfilled, (state, action) => {
            state.contactData = true;
            state.loader = false;
        });
        builder.addCase(saveContact.rejected, (state, action) => {
            state.contactData = false;
            state.loader = true;
        });
    }
});

export default masterSlice.reducer;
