import React, { useState, useEffect } from 'react'
import logo from '../../images/favicon.png'
import logo2 from '../../images/ira-logo.webp'
import { useMediaQuery } from 'react-responsive';
import { HiBars3 } from "react-icons/hi2";
import { setToggleTrue } from '../../redux/Slice/changeStates'
import { useDispatch, useSelector } from 'react-redux';
import Contact from './Contact';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

const DesktopHeader = () => {
  const [navSolid, setNavSolid] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const dispatch = useDispatch()
  const state = useSelector((state) => state);

  const handleScroll = () => {
    if (window.scrollY > 75) {
      setNavSolid(true);
    } else {
      setNavSolid(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light nav-header header">
        <div className="container-fluid">
          {
            isMobile &&
            <>
              <a className="navbar-brand " href="#">
                <img src={logo} className='first-logo' alt="Logo"></img>
              </a>
              <a className="navbar-brand mx-auto " href="#">
                <img src={logo2} alt="Logo" className='sec-logo'></img>
              </a>
            </>
          }

          {/* Toggle button on the right side and fixed */}
          <button
            className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation" 
          >
            <HiBars3 size={30} />
          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <div className="d-flex">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="facing" smooth={true} duration={1000}>
                    Floor Plans
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="Amenities" smooth={true} duration={1000}>
                    Amenities
                  </Link>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={() => dispatch(setToggleTrue())}>
                    Contact us
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* Logo in the center */}
            {!isMobile &&
              <div className="mx-auto">
                <a className="navbar-brand" href="#">
                  <img src={logo} alt="Logo"></img>
                </a>
              </div>
            }

            <div className="d-flex">
              <div className="touch-div">
                <h6>Email: info@irarealty.in</h6>
                <h6>Call Us: +91 7075 506 189</h6>
              </div>
              {!isMobile &&
                <div>
                  <img src={logo2} alt="Logo"></img>
                </div>
              }
            </div>
          </div>
        </div>
      </nav>

      {
        state.toggle.hastoggle && <Contact />
      }
    </>
  )
}

export default DesktopHeader
