import React from 'react'
import img1 from '../../images/highlight.webp'
import { useMediaQuery } from 'react-responsive'
import { Row, Col, Container } from 'react-bootstrap'
import img2 from '../../images/built-up.webp'
import img3 from '../../images/facing-icon.webp'
import img4 from '../../images/parking.webp'
import img5 from '../../images/plot.webp'
import img6 from '../../images/road.webp'
import img7 from '../../images/land.webp'
import img8 from '../../images/villa.webp'
import img9 from '../../images/wifi.webp'
import img10 from '../../images/vastu.webp'
import mob from '../../images/mob-project.webp'



const ProjectHighlight = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const extraSmallDevice = useMediaQuery({query: '(max-width: 768px)'})
  return (
    <>
      <div className='highlight-image'>
        <img src={isMobile ? mob : img1} alt="" className='img-fluid w-100'></img>
        <div className='headling'>
          <h1>Project Highlights:</h1>
          {isMobile ?
            <>
              <h1>Premium 4 bhk villas</h1>
              <h1>for sale</h1>
            </>
            :
            <h1>Premium 4 bhk villas for sale</h1>
          }

        </div>


        <Container>
          <div className='category-container'>
            {
              isMobile ?
                <>
                  <Row className='mb-3' style={{ marginLeft : isMobile ? 'unset' : ''}}>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img8} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>HMDA & RERA Approved Luxury Villa Project</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img5} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>Plot sizes: 267, 300, 330 Sq Yd</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mb-3' style={{ marginLeft : isMobile ? 'unset' : ''}}>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img7} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>13.5 Acres of land</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img9} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>Home Automation for all villas</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mb-3' style={{ marginLeft : isMobile ? 'unset' : ''}}>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}> 
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img3} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>131 East & West facing Duplex Villas</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={4}>
                          <img src={img4} alt="" ></img>
                        </Col>
                        <Col md={9} xs={8}>
                          <p>Two Car Parking spaces for each Villa</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mb-3' style={{ marginLeft : isMobile ? 'unset' : ''}}>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img6} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>40' & 30' Wide Internal BT Roads</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img10} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>100% Vastu Compliant Villas
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mb-3' style={{ marginLeft : isMobile ? 'unset' : ''}}>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                      <Row className='project-category w-100'>
                        <Col md={3} xs={3}>
                          <img src={img2} alt="" ></img>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>Built-up area: 3700 Sft for all Villas
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={6} className={`${extraSmallDevice ? 'mb-2' : ''}`}>
                    </Col>
                  </Row>
                </>
                :

                <>
                  <Row className='mb-4'>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img8} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>HMDA & RERA Approved Luxury Villa Project</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img5} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>Plot sizes: 267, 300, 330 Sq Yd</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img6} alt="" ></img>
                        </Col>
                        <Col lg={9} >
                          <p>40' & 30' Wide Internal BT Roads</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img7} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>13.5 Acres of land</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img9} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>Home Automation for all villas</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img10} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>100% Vastu Compliant Villas
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img3} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>131 East & West facing Duplex Villas</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img4} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>Two Car Parking spaces for each Villa</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={4}>
                      <Row className='project-category w-100'>
                        <Col lg={3}>
                          <img src={img2} alt="" ></img>
                        </Col>
                        <Col lg={9}>
                          <p>Built-up area: 3700 Sft for all Villas
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
            }




          </div>
        </Container>



        {/* <div className='highlights'>
          {isMobile &&
            <div className="space-40"></div>
          }
         <ul>
            <li>HMDA & RERA Approved Luxury Villa Project</li>
            <li>13.5 Acres of land</li>
            <li>Majestic Entrance Gate</li>
            <li>131 East & West facing Duplex Villas</li>
            <li>Plot sizes: 267, 300, 330 Sq Yd</li>
            <li>Built-up area: 3700 Sft for all Villas</li>
            <li>Provision for Home Automation</li>
            <li>Two Car Parking spaces for each Villa</li>
            <li>40' & 30' Wide Internal BT Roads</li>
            <li>100% Vastu</li>
            <li>Avenue Plantation, Street Lighting, Pedestrian Path, Jogging Track</li>
            <li>24-hr Water Supply, Parks & Play areas, Designer Landscaping</li>
            <li>24X7 Power Back-up</li>
          </ul>
          {isMobile &&
            <div className="space-40"></div>
          }
        </div> */}
      </div>

    </>
  )
}

export default ProjectHighlight
