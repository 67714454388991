import React from 'react'
import img from '../../images/footer1.webp'
import logo from '../../images/favicon.png'
import logo1 from '../../images/ira-logo.webp'
import { MdPhoneInTalk } from "react-icons/md";
import { PiWhatsappLogoLight } from "react-icons/pi";
import { GrFacebookOption } from "react-icons/gr";
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Contact from './Contact';
import { setToggleTrue } from '../../redux/Slice/changeStates'
import { Link } from 'react-scroll';

const Footer = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  return (
    <>

      <div className="space-60 d-none d-md-block"></div>
      <footer className='footer'>
        <div className='container'>
          <div className='row w-100'>
            <div className='col-lg-7 col-md-12'>
              <div className='row footer-first-row'>
                <div className='col-lg-9 col-md-12 d-flex align-items-end'>
                  <div className='w-100'>
                    <div className={`w-100 ${isMobile ? 'text-center' : 'd-flex'}`} style={{ marginBottom: isMobile ? '10%' : "32%", marginTop: isMobile ? '15%' : '' }}>
                      <img src={logo} alt="" className={`img-fluid ${isMobile ? 'mb-4' : ''}`} style={{ marginRight: isMobile ? '' : '40px' }}></img> {isMobile && <br />}
                      <img src={logo1} alt="" className=' img-fluid' style={{ height: '30px', marginTop: '10px' }}></img>
                    </div>
                    <div className={` w-100 ${isMobile ? "text-center" : ""}`} >

                      {isMobile && <div className='space-20'></div>}
                      <ul>
                        <li><Link to="facing" smooth={true} duration={1000}>Floor Plans</Link></li>
                        <li><a href="/">Home</a></li>
                        <li><NavLink className="nav-link" onClick={() => dispatch(setToggleTrue())}>
                          Contact us
                        </NavLink></li>
                        <li><a>FAQs</a></li>
                        <li><NavLink to="https://irarealty.in/privacy-policy">Privacy Policy</NavLink></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-3 col-md-12 ${isMobile ? "text-center" : ""}`}>
                  <img src={img} alt="" className=''></img>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-12 d-flex align-items-end'>
              <div style={{ marginLeft: !isMobile ? "50px" : "" }}>
                <div className='row'>
                  <div className='col-12'>
                    <div>
                      <p className='footer-header'>Address:</p>
                      <p className='address-text'>
                        4-49/2, Besides Anvaya Conventions Road, Financial District,</p>
                      <p className='address-text'>Vattinagulapally,</p>
                      <p className='address-text'>Hyderabad - 500 032, Telangana
                      </p>
                    </div>
                  </div>
                </div>
                <div className='space-20'></div>
                <div className='row'>
                  <div className='col-lg-6 col-md-12'>
                    <p><span className='footer-header'>Phone:</span><NavLink to="tel:9121777777" target="_blank"> +91 7075 506 189</NavLink></p>
                  </div>
                  <div className='col-lg-6 col-md-12'>
                    <p><span className='footer-header'>Email:</span><a className="text-decoration-none" href="mailto:info@irarealty.in" rel="noreferrer" target="_blank">info@irarealty.in</a></p>
                  </div>
                </div>
                <div className='row'>
                  <div className='d-flex align-items-center'>
                    <span className="footer-header" >Let Connects:</span>
                    <NavLink to="tel:7075506189" target="_blank"><MdPhoneInTalk size={35} className='padding-10' /></NavLink>
                    <NavLink to="https://wa.api-whatsapp.in/Nsyl70" target="_blank"><PiWhatsappLogoLight size={35} className='padding-10' /></NavLink>
                    <NavLink to="https://www.facebook.com/IRARealtyIndia" target="_blank"><GrFacebookOption size={35} className='padding-10' /></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='space-60'></div>
        <div className='container'>
          <p className='disclaimer'>Disclaimer: "This is purely a conceptual presentation and not a legal offering. The promoters reserve the right to make changes in elevation, specifications, and plans as deemed fit</p>
        </div>
        <div className='copyright'>
          <span>©2024  IRAREALTY</span>
        </div>
      </footer>
      {
        state.toggle.hastoggle && <Contact />
      }
    </>
  )
}

export default Footer
