import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay } from 'swiper/modules';
import slider1 from '../../images/community.webp'
import slider2 from '../../images/slider2.webp'
import slider3 from '../../images/slider3.webp'
import slider4 from '../../images/slider4.webp'
import mobsli1 from '../../images/mobSlider1.webp'
import mobsli2 from '../../images/mobSlider2.webp'
import mobsli3 from '../../images/mobSlider3.webp'
import mobsli4 from '../../images/mobSlider4.webp'
import logo from '../../images/favicon.png'
import { IoIosArrowRoundForward } from "react-icons/io";
import { setToggleTrue } from '../../redux/Slice/changeStates'
import { useDispatch, useSelector } from 'react-redux';
import Contact from './Contact';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


const Slider = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const data=[
    {
      "id": 1,
      "img": slider1,
      "mobimg": mobsli1,
    },
    {
      "id": 2,
      "img": slider2,
      "mobimg": mobsli2,
    },
    {
      "id": 3,
      "img": slider4,
      "mobimg": mobsli4,
    }
  ];
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  return (
    <>
      
      <div id="slider-section">
        
        <Swiper 
          // pagination={{
          //   clickable: true,
          // }} 
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          loop={true} 
          speed={1000}
          className="mySwiper">
          {
            data.map((ele, i)=>{
              return(
                <SwiperSlide key={i}>
                  <div className='swiper-section'>
                    {
                      isMobile ?
                      <img src={ele.mobimg} alt={ele.mobimg}></img>
                      :
                      <img src={ele.img} alt={ele.img}></img>
                    }
                    
                    
                  </div>  
                  </SwiperSlide>
                  
              )
            })
          }
        </Swiper>
        <div className='slider-text'>
          {!isMobile ? 
            <>
              <h1>Discover Luxury 4 BHK Villas in</h1>
              <h1>Adibatla's Best Gated Community</h1>
            </>
            :
            <h1>Discover Luxury 4 BHK Villas in Adibatla's Best Gated Community</h1>
          }
          <p>Own a luxurious 4 BHK villa at The Square, Adibatla.</p>
          <NavLink onClick={() => dispatch(setToggleTrue())} className="btn btn-enquiry" >Schedule a Site Visit <IoIosArrowRoundForward size={30} /></NavLink>
        </div>
      </div>
      {
        state.toggle.hastoggle && <Contact />
      }
    </>
  )
}

export default Slider
