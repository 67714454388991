import React from 'react'
import img from '../../images/smart1.webp'
import img1 from '../../images/smart2.webp'
import img2 from '../../images/smart3.webp'
import con1 from '../../images/fan.webp'
import con2 from '../../images/ir.webp'
import con3 from '../../images/ac.webp'
import con4 from '../../images/light_dimmer.webp'
import con5 from '../../images/mesh_network.webp'
import con6 from '../../images/mobile.webp'
import con7 from '../../images/sensor.webp'
import con8 from '../../images/smart_light.webp'
import con9 from '../../images/lock.webp'
import bg1 from '../../images/rectangle.png'

import { useMediaQuery } from 'react-responsive'



const Smart = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

    return (
        <div className='smart-section'>
            <div className='space-30'></div>
            <div className='container'>
                <div className='text-center'>
                    <h1>4 bhk luxury villas with home automation</h1>
                    <div className='space-10'></div>
                    {
                        isMobile ?
                            <p className='text-white'>Experience a tech-enabled lifestyle with smart home features in our 4 BHK villas in Adibatla. Enjoy convenience, security, and luxury at the touch of a button.</p>
                        :
                        <>
                            <span>Experience a tech-enabled lifestyle with smart home features in our 4 BHK villas in Adibatla. Enjoy convenience, security, and</span><br/>
                            <span>luxury at the touch of a button.</span>
                        </>
                    }
                    
                </div>
            </div>
            {
                !isMobile &&
                <div className='space-60'></div>
            }
            <div className='space-30'></div>
            <div className='container position-relative'>
            {
                !isMobile &&

                <img src={bg1} alt="" className='img-fluid background-rec'></img>
            }
                <div className='img-section'>
                    <div className='' style={{ margin: !isMobile ? '0px 10px' : '' }}>
                        <div className='row'>
                            <div className={`col-lg-6 col-md-12 col-12 ${isMobile ? "mb-4" : ""}`} style={{ paddingRight: !isMobile ? 'unset' : '' }}>
                                <img src={img} alt="" className='img-fluid frist-img' style={{ height: !isMobile ? '656px' : '', width: '100%' }}></img>
                            </div>
                            <div className='col-lg-6 col-md-12 col-12' >
                                <div className='row mb-3'>
                                    <div className='col-12'>
                                        <img src={img1} alt="" className='img-fluid'></img>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <img src={img2} alt="" className='img-fluid'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='space-60'></div>
                <div className='connect-section'>
                    <div className='connect-div'>
                        {!isMobile ?
                            <>
                                <div className="connectRow">
                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con8} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Smart Lighting</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con1} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Fans</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con3} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Air Conditioner</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con6} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Mobile App Enabled</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con9} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Smart Lock System</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="connectRow mt-4">
                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con7} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Motion Sensor For Bathrooms</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con5} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Mesh Network</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con4} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Light Dimmer</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        <div className="content">
                                            <div className="img-container">
                                                <img src={con2} alt="" className="img-fluid" />
                                            </div>
                                            <div className="text-container">
                                                <h6>Universal IR Blaster</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="connect-col equal-width">
                                        
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='row mb-4'>
                                    <div className='col-6 text-center'>
                                        <img src={con8} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Smart Lighting</h6>
                                    </div>
                                    <div className='col-6 text-center'>
                                        <img src={con1} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Fans</h6>
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-6 text-center'>
                                        <img src={con3} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Air Conditioner</h6>
                                    </div>
                                    <div className='col-6 text-center'>
                                        <img src={con6} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Mobile App Enabled</h6>
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-6 text-center'>
                                        <img src={con9} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Smart Lock System</h6>
                                    </div>
                                    <div className='col-6 text-center'>
                                        <img src={con7} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Motion Sensor For Bathrooms</h6>
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-6 text-center'>
                                        <img src={con5} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Mesh Network</h6>
                                    </div>
                                    <div className='col-6 text-center'>
                                        <img src={con4} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Light Dimmer</h6>
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-6 text-center'>
                                        <img src={con2} alt="" className='img-fluid mb-2' ></img>
                                        <h6>Universal IR Blaster</h6>
                                    </div>
                                    <div className='col-6 text-center'>
                                    </div>
                                </div>
                            </>
                        }


                    </div>
                </div>
            </div>
            <div className='space-60'></div>
            {!isMobile &&
            <div className='space-60'></div>
            }
        </div>
    )
}

export default Smart
