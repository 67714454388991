import React from 'react'
import img from '../../images/join.webp'
import { FaArrowRightLong } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive';
import { setToggleTrue } from '../../redux/Slice/changeStates'
import { useDispatch, useSelector } from 'react-redux';
import Contact from './Contact';
import { NavLink } from 'react-router-dom';

const Join = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <>
      <div className='position-relative join-div'>
        {/* {
        !isMobile && 
        
      } */}

        <div className='join-section'>
          <div className='container '>
            <div className='row'>
              <div className={`col-lg-6 col-md-12 d-flex align-items-center ${isMobile ? 'justify-content-center' : 'justify-content-start'}`}>
                <div className='join-text'>
                  <h1 className='text-uppercase'>Join Us to Visit </h1>
                  <h1 className='text-uppercase'>YOur Dream Villa</h1>
                  <h1 className='text-uppercase'>Today</h1>
                  <p>Discover your dream 4 BHK villa in Adibatla. Schedule a visit today to experience the luxury lifestyle at The Square, Adibatla.</p>
                  <div>
                    <button className='schedule-button'><NavLink onClick={() => dispatch(setToggleTrue())} >Schedule a Site Visit <FaArrowRightLong /></NavLink></button>{isMobile && <br />}
                    {/* <button className='contact-button'><NavLink to="tel:9121777777" target="_blank">Contact Us <FaArrowRightLong /></NavLink></button> */}
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-12'>
                <img src={img} alt="" className='img-fluid'></img>
              </div>
            </div>
          </div>
        </div>

      </div>
      {
        state.toggle.hastoggle && <Contact />
      }
    </>
  )
}

export default Join
